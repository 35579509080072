<template>
    <div>
        

        <!--begin::User-->
        <div class="card card-custom">

            <div class="card-body">
                <!-- <div class="d-flex justify-content-end mb-5">
                    <export-data :data-list="dataList" :file-name="fileName" :fields="json_fields"></export-data>
                </div> -->
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                   
                    <!-- <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('register_company.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('register_company.delete')" @click="deleteregister_company(props.row)">mdi-delete</v-icon>
                    </template> -->
                </v-server-table>
            </div>
        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import Vue from "vue";
    // import jsPDF from 'jspdf'
    // import 'jspdf-autotable'

    export default {
        name: "index",
        components: {},
        data() {
            return {
                register_company: {
                    name: '',
                    is_active: false,
                },
                showAdvancedSearch: false,
                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: '',
                    is_active: '',
                    notes: ''
                },
                columns: ['name' ,'end_point' ,'method' ,'type' ,'from_name','to_name','description'],
                validation: null,
                mainRoute: 'settings/sync_endpoints',
                routeChangeStatus: 'settings/business-field/change-status',
                isEditing: false,
                id: null,
                dataList: [],
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
            }
        },
        watch: {
            // 'register_company.name': function (val) {
            //     if (val) {
            //         this.register_company.name = val.replace(/[\d]/g, "");
            //     }
            // },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('sync_endpoint.name')] = 'name';
                fields[this.$t('sync_endpoint.end_point')] = 'end_point';
                fields[this.$t('sync_endpoint.method')] = 'method';
                fields[this.$t('sync_endpoint.type')] = 'type';
                fields[this.$t('sync_endpoint.from')] = 'from';
                fields[this.$t('sync_endpoint.to')] = 'to';
                fields[this.$t('sync_endpoint.description')] = 'description';
                return fields;
            },
            // fileName: function () {
            //     return this.$t('register_company.register_company');
            // },
            options: function () {
                let that = this;
                return {

                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('sync_endpoint.name'),
                        end_point: that.$t('sync_endpoint.end_point'),
                        method: that.$t('sync_endpoint.method'),
                        type: that.$t('sync_endpoint.type'),
                        from_name: that.$t('sync_endpoint.from'),
                        to_name: that.$t('sync_endpoint.to'),
                        description: that.$t('sync_endpoint.description'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',
                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params}).catch(function (error) {
                            console.error(error)
                        })

                    },
                    responseAdapter(resp) {

                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sync_endpoint")}]);
            // let that = this;
            this.$root.$on('bv::modal::hidden', () => {
                this.afterSaveBusiness();
            });


        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    is_active: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    console.log(error);
                });
            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.is_active = '';

                this.$refs.table.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.updateBusiness();
                } else {
                    this.saveBusiness();
                }
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            actionDelete(register_company) {
                ApiService.delete(`${this.mainRoute}/${register_company.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch(({errors}) => {
                    console.error(errors);
                })
            },
            deleteregister_company(register_company) {
                this.$confirmAlert('', this.actionDelete, register_company);
            },

            showModal(data) {
                if (!data.id) {
                    this.reset();
                    this.$refs['modal'].show()
                } else {
                    this.isEditing = true;
                    this.id = data.id;
                    this.getData(data);
                    this.$refs['modal'].show();
                }

            },
            hideModal() {
                this.reset();
                this.$refs['modal'].hide()
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },
            saveBusiness() {
                ApiService.post(this.mainRoute, this.register_company).then((response) => {
                    this.$successAlert(response.data.message);
                    this.hideModal();
                    this.afterSaveBusiness();
                }).catch((errors) => {
                  Vue.prototype.$postStatus = true;
                    this.$errorAlert(errors);
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },
            updateBusiness() {
                ApiService.put(`${this.mainRoute}/${this.id}`, {
                    id: this.id,
                    ...this.register_company,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.validation = null;
                    this.hideModal();
                    this.afterSaveBusiness();
                })
                    .catch((errors) => {
                      Vue.prototype.$postStatus = true;
                        this.$errorAlert(errors);
                        this.validation = errors.response ? errors.response.data.errors : null;
                    });
            },
            getData(data) {
                this.register_company.name = data.name;
                this.register_company.is_active = data.is_active;
            },
            reset() {
                this.register_company = {
                    name: '',
                    is_active: false,
                };
                this.validation = [];
                this.isEditing = false;
            },
            afterSaveBusiness() {
                this.register_company = {
                    name: '',
                    is_active: false,
                };
                this.validation = null;
                // this.hideModal();
                this.getFetch();
            }
        },
    };
</script>
